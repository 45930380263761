import { CButton, CCard, CCardBody, CContainer } from '@coreui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import URL from 'src/apis/URL'
import UserInput from 'src/components/UserInputs'

const AddSpecialUser = () => {
  const navigate = useNavigate()
  const { user_code: userCode, filter } = useParams()
  const [users, setUsers] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState([
    {
      name: '',
      phone_number: '',
      gender: '',
      status: '',
      city: '',
      age: '',
    },
    {
      name: '',
      phone_number: '',
      gender: '',
      status: '',
      city: '',
      age: '',
    },
    {
      name: '',
      phone_number: '',
      gender: '',
      status: '',
      city: '',
      age: '',
    },
  ])

  useEffect(() => {
    const url = URL.BASE_URL + URL.END_POINT.getSpecialUsers + userCode
    fetch(`${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data) && data.data?.length > 0) {
            setFormData(data.data)
            setIsEdit(true)
          }
        }
      })
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    const url = URL.BASE_URL + URL.END_POINT.appusers
    fetch(`${url}?limit=100&filter=${filter || 'Male'}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUsers(data.app_users))
      .catch((error) => console.log(error))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(
        URL.BASE_URL + URL.END_POINT.createSpecialUser + userCode + `?isEdit=${isEdit}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            USERID: localStorage.getItem('USERID'),
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(formData),
        },
      )

      if (!response.ok) {
        const message = await response.json().then((res) => res.message)
        throw new Error(message)
      }
      toast('Special User created successfully! ', { type: 'success' })

      // setFormData({
      //   name: '',
      //   phone_number: '',
      //   gender: '',
      //   status: '',
      //   city: '',
      //   age: '',
      // })
      navigate('/package/special-users')
    } catch (error) {
      toast(error.message, { type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const userList = useMemo(
    () => users.map((user, index) => ({ label: user?.email_id, value: index })),
    [users],
  )

  return (
    <CContainer>
      <CCard>
        <CCardBody className="p-4">
          <h1 className="mb-3">Assign User</h1>
          {formData.map((formItem, index) => (
            <UserInput
              key={index}
              userList={userList}
              users={users}
              formItem={formItem}
              setFormData={setFormData}
              index={index}
              isLastItem={index == 2}
            />
          ))}
          <div className="d-flex justify-content-center my-4">
            <CButton
              color="success"
              className="w-50 text-white"
              disabled={formData.some((data) => data.name === '') || isLoading}
              onClick={handleSubmit}
            >
              Submit
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </CContainer>
  )
}

export default AddSpecialUser
