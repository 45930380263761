import Addusers from './views/pages/appusers/Addusers'
import Appusers from './views/pages/appusers/Appusers'
import Showusers from './views/pages/appusers/Showusers'
import Adminusers from './views/pages/adminusers/Adminusers'
import PackageUpgrade from './views/pages/packageupgrade/PackageUpgrade'
import PackageHistory from './views/pages/packagehistory/PackageHistory'
import Bankdetails from './views/pages/bankdetails/Bankdetails'
import Blockusers from './views/pages/blockusers/Blockusers'
import Notification from './views/pages/notification/Notification'
import ReligionData from './views/pages/masterdata/ReligionData'
import SectData from './views/pages/masterdata/SectData'
import CommunityData from './views/pages/masterdata/CommunityData'
import CasteData from './views/pages/masterdata/CasteData'
import FatherStatusData from './views/pages/masterdata/FatherStatusData'
import MotherStatusData from './views/pages/masterdata/MotherStatusData'
import BrotherStatusData from './views/pages/masterdata/BrotherStatusData'
import SisterStatusData from './views/pages/masterdata/SisterStatusData'
import MarriedStatusData from './views/pages/masterdata/MarriedStatusData'
import HeightData from './views/pages/masterdata/HeightData'
import EducationLevelData from './views/pages/masterdata/EducationLevelData'
import DegreeTitleData from './views/pages/masterdata/DegreeTitleData'
import WorkingSectorData from './views/pages/masterdata/WorkingSectorData'
import LivingStatusData from './views/pages/masterdata/LivingStatusData'
import ResidenceStatusData from './views/pages/masterdata/ResidenceStatusData'
import ResidenceSizeData from './views/pages/masterdata/ResidenceSizeData'
import SkintoneArray from './views/pages/masterdata/SkintoneArray'
import ReportTitleOptions from './views/pages/masterdata/ReportTitleOptions'
import MaritalStatusOptions from './views/pages/masterdata/MaritalStatusOptions'
import PackageUsers from './views/pages/packageusers/PackageUsers'
import Dashboard from './views/dashboard/Dashboard'
import ReportUsers from './views/pages/reportusers/ReportUsers'
import Editusers from './views/pages/appusers/Editusers'
import SpecialUsers from './views/pages/special/SpecialUsers'
import AddSpecialUser from './views/pages/special/AddSpecialUser'
import EditSpecialUser from './views/pages/special/EditSpecialUser'
import SpecialPackageHistory from './views/pages/specialPackageHistory/SpecialPackageHistory'
import AdsPackageHistory from './views/pages/adsPackageHistory/AdsPackageHistory'
import SpecialPackageUsers from './views/pages/specialPackageUsers'
import AdsPackageUsers from './views/pages/adsPackageUsers/AdsPackageUsers'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/users/admin', name: 'Admin users', element: Adminusers },
  { path: '/users/all', name: 'App users', element: Appusers },
  { path: '/users/new', name: 'Add users', element: Addusers },
  { path: '/users/special', name: 'Special users', element: SpecialUsers },
  { path: '/users/add-special', name: 'Add special users', element: AddSpecialUser },
  { path: '/users/edit-special', name: 'Add special users', element: EditSpecialUser },
  { path: '/users/:id', name: 'User Details', element: Showusers },
  { path: '/users/edit/:id', name: 'User Update', element: Editusers },
  { path: '/users/block', name: 'Block users', element: Blockusers },
  { path: '/reports/users', name: 'Reports Users', element: ReportUsers },
  { path: '/package/users', name: 'Subscription Users', element: PackageUsers },
  { path: '/package/special-users', name: 'Subscription Users', element: SpecialPackageUsers },
  {
    path: '/package/special-users/:user_code',
    name: '',
    element: AddSpecialUser,
  },
  { path: '/package/ads-users', name: 'Subscription Users', element: AdsPackageUsers },
  { path: '/package/upgrade', name: 'Package Upgrade', element: PackageUpgrade },
  { path: '/package/approval', name: 'Package History', element: PackageHistory },
  {
    path: '/package/special/approval',
    name: 'Special Package History',
    element: SpecialPackageHistory,
  },
  { path: '/package/ads/approval', name: 'Ads Package History', element: AdsPackageHistory },
  { path: '/payment/details', name: 'Bank Details', element: Bankdetails },
  { path: '/master/religion', name: 'Religion', element: ReligionData },
  { path: '/master/sect', name: 'Sect', element: SectData },
  { path: '/master/community', name: 'Community', element: CommunityData },
  { path: '/master/caste', name: 'Caste', element: CasteData },
  { path: '/master/father-status', name: 'Father Status', element: FatherStatusData },
  { path: '/master/mother-status', name: 'Mother Status', element: MotherStatusData },
  { path: '/master/brother-status', name: 'Brother Status', element: BrotherStatusData },
  { path: '/master/sister-status', name: 'Sister Status', element: SisterStatusData },
  { path: '/master/married-status', name: 'Married Status', element: MarriedStatusData },
  { path: '/master/height', name: 'Height', element: HeightData },
  { path: '/master/education-level', name: 'Education Level', element: EducationLevelData },
  { path: '/master/degree-title', name: 'Degree Title', element: DegreeTitleData },
  { path: '/master/working-sector', name: 'Working Sector', element: WorkingSectorData },
  { path: '/master/living-status', name: 'Living Status', element: LivingStatusData },
  { path: '/master/residence-status', name: 'Residence Status', element: ResidenceStatusData },
  { path: '/master/residence-size', name: 'Residence Size', element: ResidenceSizeData },
  { path: '/master/skintone', name: 'Skintone', element: SkintoneArray },
  { path: '/master/report-title', name: 'Report Title', element: ReportTitleOptions },
  { path: '/master/marital-status', name: 'Marital Status', element: MaritalStatusOptions },
  { path: '/notification', name: 'Notification', element: Notification },
]

export default routes
