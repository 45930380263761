import { cilPen, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CButtonGroup,
  CContainer,
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppSpinner } from 'src/components'
import { deleteSpecialUser, getSpecialUsers } from 'src/redux/specialuser'
import { useTableSelection } from 'src/utils/useTableSelection'

const SpecialUsers = () => {
  const headers = ['Name', 'Mobile', 'Gender', 'Status', 'City', 'Age', 'Action']

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getSpecialUsers())
  }, [dispatch])

  const { specialUsers, isLoading } = useSelector((state) => {
    return {
      specialUsers: state?.specialUsers?.specialUsers?.data,
      isLoading: state?.specialUsers?.loadingSpecialUsers,
    }
  })

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } =
    useTableSelection(specialUsers)

  const handleDelete = (id) => {
    dispatch(deleteSpecialUser(id))
    setTimeout(() => {
      dispatch(getSpecialUsers())
    }, 1000)
  }
  const handleEdit = (userId) => {
    navigate('/users/edit-special', { state: { userId } })
  }
  return (
    <>
      {isLoading ? (
        <CContainer className="d-flex justify-content-center align-items-center">
          <AppSpinner />
        </CContainer>
      ) : (
        <CTable id="table-to-xls-current" align="middle" className="mb-3 border" hover responsive>
          <CTableHead color="light">
            <CTableRow>
              <CTableHeaderCell>
                <CFormCheck checked={isAllSelected} onChange={toggleSelectAll} color="primary" />
              </CTableHeaderCell>
              {headers.map((item) => (
                <CTableHeaderCell key={item}>{item}</CTableHeaderCell>
              ))}
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {specialUsers?.map((item, index) => (
              <CTableRow v-for="item in tableItems" key={index}>
                <CTableDataCell>
                  <CFormCheck
                    checked={selectedItems.includes(item.id)}
                    onChange={() => toggleItemSelection(item.id)}
                    color="primary"
                  />
                </CTableDataCell>
                <CTableDataCell>{item.name}</CTableDataCell>
                <CTableDataCell>{item.phone_number}</CTableDataCell>
                <CTableDataCell>{item.gender}</CTableDataCell>
                <CTableDataCell>{item.status}</CTableDataCell>
                <CTableDataCell>{item.city}</CTableDataCell>
                <CTableDataCell>{item.age}</CTableDataCell>
                <CTableDataCell>
                  <CButtonGroup role="group">
                    <CButton color="warning" size="sm" onClick={() => handleEdit(item.id)}>
                      <CIcon icon={cilPen} className="text-white" />
                    </CButton>
                    <CButton color="danger" size="sm" onClick={() => handleDelete(item.id)}>
                      <CIcon icon={cilTrash} className="text-white" />
                    </CButton>
                  </CButtonGroup>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      )}
    </>
  )
}

export default SpecialUsers
