/* eslint-disable react/prop-types */
import {
  cilAddressBook,
  cilBellExclamation,
  cilFingerprint,
  cilGroup,
  cilMobile,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCol,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import React, { useState } from 'react'

const UserInput = ({ userList, users, isLastItem, formItem, setFormData, index }) => {
  const [selectUser, setSelectUser] = useState('')

  const handleOnChange = (event) => {
    let data = users[event.target.value]
    setSelectUser(event.target.value)

    let bodyFormat = {
      age: data?.age,
      city: data?.city,
      gender: data?.sex,
      name: data?.name,
      phone_number: data?.mobile_no,
      status: data?.martial_status,
      user_code: data?.user_code,
    }
    setFormData((formdata) => {
      let _formdata = [...formdata]
      _formdata[index] = bodyFormat
      return _formdata
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    let bodyFormat = { ...formItem, [name]: value }
    setFormData((formdata) => {
      let _formdata = [...formdata]
      _formdata[index] = bodyFormat
      return _formdata
    })
  }

  const handleCancel = (e) => {
    setSelectUser('')
    let emptyBody = {
      name: '',
      phone_number: '',
      gender: '',
      status: '',
      city: '',
      age: '',
    }
    setFormData((formdata) => {
      let _formdata = [...formdata]
      _formdata[index] = emptyBody
      return _formdata
    })
  }

  return (
    <>
      <CRow>
        <CCol sm={12} md={6} lg={3}>
          <CFormSelect
            value={selectUser}
            onChange={handleOnChange}
            options={[{ label: 'Select Option', value: '', disabled: true }, ...userList]}
            size="sm"
            className="mb-3"
          />
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilUser} />
            </CInputGroupText>
            <CFormInput
              placeholder="Name"
              name="name"
              value={formItem?.name}
              onChange={handleChange}
              disabled={selectUser !== ''}
            />
          </CInputGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilMobile} />
            </CInputGroupText>
            <CFormInput
              name="phone_number"
              placeholder="Mobile"
              value={formItem?.phone_number}
              onChange={handleChange}
              disabled={selectUser !== ''}
            />
          </CInputGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilGroup} />
            </CInputGroupText>
            <CFormSelect
              name="gender"
              value={formItem?.gender}
              onChange={handleChange}
              disabled={selectUser !== ''}
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </CFormSelect>
          </CInputGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilBellExclamation} />
            </CInputGroupText>
            <CFormInput
              name="status"
              placeholder="Status"
              value={formItem?.status}
              onChange={handleChange}
              disabled={selectUser !== ''}
            />
          </CInputGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CInputGroup className="mb-3">
            <CInputGroupText>
              <CIcon icon={cilAddressBook} />
            </CInputGroupText>
            <CFormInput
              name="city"
              placeholder="City"
              value={formItem?.city}
              onChange={handleChange}
              disabled={selectUser !== ''}
            />
          </CInputGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CInputGroup className="mb-4">
            <CInputGroupText>
              <CIcon icon={cilFingerprint} />
            </CInputGroupText>
            <CFormInput
              name="age"
              placeholder="Age"
              value={formItem?.age}
              disabled={selectUser !== ''}
              onChange={handleChange}
            />
          </CInputGroup>
        </CCol>
        <CCol sm={12} md={6} lg={3}>
          <CButton color="danger" className="w-100 text-white" onClick={handleCancel}>
            Cancel
          </CButton>
        </CCol>
      </CRow>
      {!isLastItem && <hr />}
    </>
  )
}

export default UserInput
