import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CFormSwitch,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilTrash } from '@coreui/icons'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addPackage, deletePackage, getPackages, updatePackage } from 'src/redux/packages'
import { AppSpinner } from 'src/components'
import { calculateDiscountedAmount } from 'src/utils/calculateDiscountedAmount'

const PackageUpgrade = () => {
  const [formData, setFormData] = useState({
    package_name: '',
    package_amount: '',
    package_duration: '',
    package_discount: '',
    package_description: '',
    package_type: '',
  })

  const [isUpdate, setIsUpdate] = useState(false)

  const dispatch = useDispatch()

  const { packages, isLoading } = useSelector((state) => {
    return { packages: state.packages.packages, isLoading: state.packages.loadingPackages }
  })

  const durationResolver = (pk_duration) => {
    switch (pk_duration) {
      case 0 || '0':
        return 'Lifetime'
      default:
        return `${pk_duration} Month`
    }
  }

  useEffect(() => {
    dispatch(getPackages())
  }, [dispatch, isUpdate])

  const handleSubmit = async (dispatchFunction) => {
    if (!formData.package_name) {
      toast('Package name required!', { type: 'info' })
    } else if (!formData.package_amount) {
      toast('Package amount required!', { type: 'info' })
    } else if (!formData.package_duration) {
      toast('Package duration required!', { type: 'info' })
    } else {
      try {
        await dispatch(dispatchFunction)
        setFormData({
          package_name: '',
          package_amount: '',
          package_duration: '',
          package_discount: '',
          package_description: '',
          package_type: '',
        })
      } catch (error) {
        toast(error.message, { type: 'error' })
      } finally {
      }
    }
  }

  const handleVisible = async (dispatchFunction) => {
    try {
      await dispatch(dispatchFunction)
      setFormData({
        package_name: '',
        package_amount: '',
        package_duration: '',
        package_discount: '',
        package_description: '',
        package_type: '',
      })
    } catch (error) {
      toast(error.message, { type: 'error' })
    } finally {
    }
  }

  function handleDelete(id) {
    dispatch(deletePackage(id))
  }

  return (
    <CContainer>
      <CRow className="justify-content-center gap-3">
        <CCol xs={12}>
          <CCard className="">
            <CCardBody className="p-4">
              <CForm>
                <h5 className="text-medium-emphasis mb-3">Add Package</h5>
                <CRow className="justify-content-end">
                  <CCol xs={12} lg={4} md={6}>
                    <CFormInput
                      placeholder="Package name"
                      autoComplete="Package name"
                      className="mb-3"
                      value={formData.package_name}
                      onChange={(e) => setFormData({ ...formData, package_name: e.target.value })}
                    />
                  </CCol>
                  <CCol xs={12} lg={4} md={6}>
                    <CFormInput
                      type="number"
                      placeholder="Package amount"
                      className="mb-3"
                      autoComplete="Package amount"
                      value={formData.package_amount}
                      onChange={(e) => setFormData({ ...formData, package_amount: e.target.value })}
                    />
                  </CCol>
                  <CCol xs={12} lg={4} md={6}>
                    <CFormSelect
                      className="mb-3"
                      value={formData?.package_duration}
                      onChange={(e) =>
                        setFormData({ ...formData, package_duration: e.target.value })
                      }
                    >
                      <option value="" disabled>
                        Select Package Duration
                      </option>
                      <option value={0}>Lifetime</option>
                      {Array.from({ length: 12 }).map((_, index) => (
                        <option value={index + 1} key={index}>
                          {index + 1} Month
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                  <CCol xs={12} lg={4} md={6}>
                    <CFormInput
                      className="mb-3"
                      type="number"
                      placeholder="Discount (Precentage)"
                      max="99"
                      min="0"
                      value={formData.package_discount}
                      onChange={(e) =>
                        setFormData({ ...formData, package_discount: e.target.value })
                      }
                    />
                  </CCol>
                  <CCol xs={12} lg={4} md={6}>
                    <CFormInput
                      className="mb-3"
                      type="text"
                      placeholder="Description"
                      value={formData.package_description}
                      onChange={(e) =>
                        setFormData({ ...formData, package_description: e.target.value })
                      }
                    />
                  </CCol>
                  <CCol xs={12} lg={4} md={6}>
                    <CFormSelect
                      className="mb-3"
                      value={formData?.package_type}
                      onChange={(e) => setFormData({ ...formData, package_type: e.target.value })}
                    >
                      <option value="" disabled>
                        Select Package Type
                      </option>
                      <option value="normal">Normal</option>
                      <option value="special">Special</option>
                      <option value="ads">Ads</option>
                    </CFormSelect>
                    {formData.package_discount * 1 >= 100 && formData.package_discount ? (
                      <div className="text-danger small mb-2">
                        Discount value must be smaller than 100%
                      </div>
                    ) : (
                      ''
                    )}
                  </CCol>
                  <CCol xs={12} lg={4} md={6}>
                    <div className="d-grid">
                      <CButton
                        color="success"
                        onClick={() => {
                          formData?.id
                            ? handleSubmit(updatePackage(formData?.id, formData))
                            : handleSubmit(addPackage(formData))
                          setTimeout(() => {
                            setIsUpdate(!isUpdate)
                          }, 2000)
                        }}
                        disabled={formData.package_discount * 1 >= 100}
                      >
                        {formData?.id ? 'Update' : 'Submit'}
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={12}>
          {isLoading ? (
            <CContainer className="d-flex justify-content-center align-items-center">
              <AppSpinner />
            </CContainer>
          ) : (
            <>
              {Array.isArray(packages.upgrade) && packages?.upgrade?.length === 0 ? (
                <h3>Package is empty. Please create a new package.</h3>
              ) : (
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell className="text-center">Sr</CTableHeaderCell>
                      <CTableHeaderCell>Name</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Duration</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Amount</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Discount</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Package Value</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Package Type</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Package Lock</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>

                  <CTableBody>
                    {packages?.upgrade?.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={index}>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <small className="text-medium-emphasis">{index + 1} </small>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div className="text-medium-emphasis">
                            <strong>{item.package_name}</strong>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <h6 className="text-medium-emphasis">
                              {durationResolver(item.package_duration)}
                            </h6>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <h6 className="text-medium-emphasis">
                              {parseInt(item.package_amount)}
                            </h6>
                          </div>
                        </CTableDataCell>

                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <h6 className="text-medium-emphasis">{item.package_discount ?? 0}</h6>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <h6 className="text-medium-emphasis">
                              {calculateDiscountedAmount(
                                item.package_amount * 1,
                                item.package_discount,
                              )}
                            </h6>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <h6 className="text-medium-emphasis text-capitalize">
                              {item.package_type}
                            </h6>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell align="center">
                          <CFormSwitch
                            className="d-flex justify-content-center"
                            checked={!item?.is_visible}
                            onChange={(event) => {
                              handleVisible(
                                updatePackage(item?.id, { is_visible: !event.target.checked }),
                              ).then(() => {
                                dispatch(getPackages())
                              })
                            }}
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <CButtonGroup role="group" aria-label="Basic mixed styles example">
                            <CButton color="warning" size="sm" onClick={() => setFormData(item)}>
                              <CIcon icon={cilPencil} className="text-white" />
                            </CButton>
                            <CButton color="danger" size="sm" onClick={() => handleDelete(item.id)}>
                              <CIcon icon={cilTrash} className="text-white" />
                            </CButton>
                          </CButtonGroup>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              )}
            </>
          )}
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default PackageUpgrade
