import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getPackagesRequest = () => ({
  type: 'GET_PACKAGES_REQUEST',
})

export const getPackagesSuccess = (data) => ({
  type: 'GET_PACKAGES_SUCCESS',
  data,
})

export const getPackagesFailure = (error) => ({
  type: 'GET_PACKAGES_FAILURE',
  error,
})

export const addPackageRequest = () => ({
  type: 'ADD_PACKAGE_REQUEST',
})

export const addPackageSuccess = () => ({
  type: 'ADD_PACKAGE_SUCCESS',
})

export const addPackageFailure = (error) => ({
  type: 'ADD_PACKAGE_FAILURE',
  error,
})

export const updatePackageRequest = () => ({
  type: 'UPDATE_PACKAGE_REQUEST',
})

export const updatePackageSuccess = () => ({
  type: 'UPDATE_PACKAGE_SUCCESS',
})

export const updatePackageFailure = (error) => ({
  type: 'UPDATE_PACKAGE_FAILURE',
  error,
})

export const deletePackageRequest = () => ({
  type: 'DELETE_PACKAGE_REQUEST',
})

export const deletePackageSuccess = () => ({
  type: 'DELETE_PACKAGE_SUCCESS',
})

export const deletePackageFailure = (error) => ({
  type: 'DELETE_PACKAGE_FAILURE',
  error,
})

export const getPackageHistoryRequest = () => ({
  type: 'GET_PACKAGE_HISTORY_REQUEST',
})

export const getPackageHistorySuccess = (data) => ({
  type: 'GET_PACKAGE_HISTORY_SUCCESS',
  data,
})

export const getPackageHistoryFailure = (error) => ({
  type: 'GET_PACKAGE_HISTORY_FAILURE',
  error,
})

export const packageApprovalRequest = () => ({
  type: 'PACKAGE_APPROVAL_REQUEST',
})

export const packageApprovalSuccess = () => ({
  type: 'PACKAGE_APPROVAL_SUCCESS',
})

export const packageApprovalFailure = (error) => ({
  type: 'PACKAGE_APPROVAL_FAILURE',
  error,
})

export const getPackages = () => {
  return (dispatch) => {
    dispatch(getPackagesRequest())

    fetch(URL.BASE_URL + URL.END_POINT.getpackages, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getPackagesSuccess(data))
      })
      .catch((error) => {
        dispatch(getPackagesFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const addPackage = (newData) => {
  return (dispatch) => {
    dispatch(addPackageRequest())

    fetch(URL.BASE_URL + URL.END_POINT.addpackages, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(addPackageSuccess())
        toast.success('Package added successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(addPackageFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const updatePackage = (id, updatedData) => {
  return (dispatch) => {
    dispatch(updatePackageRequest())

    if ('createdAt' in updatedData) delete updatedData.createdAt
    if ('id' in updatedData) delete updatedData.id
    if ('updatedAt' in updatedData) delete updatedData.updatedAt

    fetch(URL.BASE_URL + URL.END_POINT.updatepackages + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(updatePackageSuccess())
        toast.success('Package updated successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(updatePackageFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const deletePackage = (id) => {
  return (dispatch) => {
    dispatch(deletePackageRequest())

    fetch(URL.BASE_URL + URL.END_POINT.deletepackages + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deletePackageSuccess())
        toast.success('Package deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deletePackageFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const getPackageHistory = (page, search, filter) => {
  return (dispatch) => {
    dispatch(getPackageHistoryRequest())

    let url = URL.BASE_URL + URL.END_POINT.packagehistory

    let obj = {}

    if (page) obj.page = page
    if (search) obj.search = search
    if (filter) obj.filter = filter

    Object.keys(obj).forEach((key, index) => {
      if (index === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getPackageHistorySuccess(data))
      })
      .catch((error) => {
        dispatch(getPackageHistoryFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const getSpecialPackageHistory = (page, search, filter) => {
  return (dispatch) => {
    dispatch(getPackageHistoryRequest())

    let url = URL.BASE_URL + URL.END_POINT.packagehistoryspecial

    let obj = {}

    if (page) obj.page = page
    if (search) obj.search = search
    if (filter) obj.filter = filter

    Object.keys(obj).forEach((key, index) => {
      if (index === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getPackageHistorySuccess(data))
      })
      .catch((error) => {
        dispatch(getPackageHistoryFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const getAdsPackageHistory = (page, search, filter) => {
  return (dispatch) => {
    dispatch(getPackageHistoryRequest())

    let url = URL.BASE_URL + URL.END_POINT.packagehistoryads

    let obj = {}

    if (page) obj.page = page
    if (search) obj.search = search
    if (filter) obj.filter = filter

    Object.keys(obj).forEach((key, index) => {
      if (index === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getPackageHistorySuccess(data))
      })
      .catch((error) => {
        dispatch(getPackageHistoryFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const packageApproval = (newData) => {
  return (dispatch) => {
    dispatch(packageApprovalRequest())

    fetch(URL.BASE_URL + URL.END_POINT.packageapproval, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        await dispatch(packageApprovalSuccess())
        toast('Package update successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(packageApprovalFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const packageSpecialApproval = (newData) => {
  return (dispatch) => {
    dispatch(packageApprovalRequest())

    fetch(URL.BASE_URL + URL.END_POINT.packageapprovalspecial, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        await dispatch(packageApprovalSuccess())
        toast('Package update successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(packageApprovalFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

export const packageAdsApproval = (newData) => {
  return (dispatch) => {
    dispatch(packageApprovalRequest())

    fetch(URL.BASE_URL + URL.END_POINT.packageapprovalads, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newData),
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        await dispatch(packageApprovalSuccess())
        toast('Package update successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(packageApprovalFailure(error.message))
        toast.error(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  packages: [],
  loadingPackages: false,
  errorPackages: null,
}

export const packagesReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_PACKAGES_REQUEST':
    case 'GET_PACKAGE_HISTORY_REQUEST':
      return {
        ...state,
        loadingPackages: true,
        errorPackages: null,
      }
    case 'GET_PACKAGES_SUCCESS':
    case 'GET_PACKAGE_HISTORY_SUCCESS':
      return {
        ...state,
        packages: action.data,
        loadingPackages: false,
        errorPackages: null,
      }
    case 'GET_PACKAGES_FAILURE':
    case 'GET_PACKAGE_HISTORY_FAILURE':
      return {
        ...state,
        loadingPackages: false,
        errorPackages: action.error,
      }
    case 'ADD_PACKAGE_REQUEST':
      return {
        ...state,
        loadingPackages: true,
      }
    case 'UPDATE_PACKAGE_REQUEST':
    case 'DELETE_PACKAGE_REQUEST':
    case 'PACKAGE_APPROVAL_REQUEST':
      return {
        ...state,
        loadingPackages: true,
        errorPackages: null,
      }
    case 'ADD_PACKAGE_SUCCESS':
    case 'UPDATE_PACKAGE_SUCCESS':
    case 'DELETE_PACKAGE_SUCCESS':
    case 'PACKAGE_APPROVAL_SUCCESS':
      return {
        ...state,
        loadingPackages: false,
        errorPackages: null,
      }
    case 'ADD_PACKAGE_FAILURE':
    case 'UPDATE_PACKAGE_FAILURE':
    case 'DELETE_PACKAGE_FAILURE':
    case 'PACKAGE_APPROVAL_FAILURE':
      return {
        ...state,
        loadingPackages: false,
        errorPackages: action.error,
      }
    default:
      return state
  }
}
