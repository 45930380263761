import React, { useEffect, useState } from 'react'
import {
  CButton,
  CCloseButton,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMoney, cilUser, cilUserFemale } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPackageHistory,
  getSpecialPackageHistory,
  packageApproval,
  packageSpecialApproval,
} from 'src/redux/packages'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Pagination from 'react-js-pagination'
import { DateTimeConverter } from 'src/utils/DateTimeConverter'
import { AppSpinner } from 'src/components'
import { useTableSelection } from 'src/utils/useTableSelection'

const SpecialPackageHistory = () => {
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 20
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const dispatch = useDispatch()

  const { packages, isLoading } = useSelector((state) => {
    return { packages: state.packages.packages, isLoading: state.packages.loadingPackages }
  })

  const { selectedItems, toggleItemSelection, toggleSelectAll, isAllSelected } = useTableSelection(
    packages?.history,
  )

  useEffect(() => {
    dispatch(getSpecialPackageHistory(currentPage, search, filter))
  }, [dispatch, loading, currentPage, search, filter])

  const filteredpackages = packages?.history?.filter((item) => {
    return (
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.transaction_id.toLowerCase().includes(search.toLowerCase())
    )
  })

  // const indexOfLastItem = currentPage * itemsPerPage
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage
  // const currentItems = filteredpackages?.slice(indexOfFirstItem, indexOfLastItem)

  const totalItems = filteredpackages?.length || 0
  // const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const ApprovedBtn = async (status, user_code) => {
    setLoading(true)
    const body = {
      status: status,
      user_code: user_code,
    }
    await dispatch(packageSpecialApproval(body))
    setTimeout(() => {
      dispatch(getSpecialPackageHistory(currentPage, search, filter))
    }, 1000)
    setLoading(false)
  }

  return (
    <CContainer>
      <CRow>
        <CCol sm={12} md={8} className="mb-2">
          <CFormInput
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </CCol>
        <CCol sm={11} md={3} className="mb-2">
          <CFormSelect
            size="sm"
            color="info"
            options={[
              { label: 'Status', value: '', disabled: true },
              ...['Pending', 'Accepted'].map((v) => ({ label: v, value: v })),
            ]}
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
        </CCol>
        <CCol sm={1} className="mb-2">
          <CCloseButton
            onClick={(e) => {
              setSearch('')
              setFilter('')
              setCurrentPage(1)
            }}
          />
        </CCol>
        {isLoading ? (
          <CContainer className="d-flex justify-content-center align-items-center">
            <AppSpinner />
          </CContainer>
        ) : (
          <>
            <CCol sm={12} className="mb-2">
              <PerfectScrollbar style={{ height: '100%' }}>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      {/* <CTableHeaderCell>
                        <CFormCheck
                          checked={isAllSelected}
                          onChange={toggleSelectAll}
                          color="primary"
                        />
                      </CTableHeaderCell> */}
                      <CTableHeaderCell>Username</CTableHeaderCell>
                      <CTableHeaderCell>Email</CTableHeaderCell>
                      <CTableHeaderCell>Package name</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Amount</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Start date</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Expiry date</CTableHeaderCell>
                      <CTableHeaderCell>Payment method</CTableHeaderCell>
                      <CTableHeaderCell>Status</CTableHeaderCell>
                      <CTableHeaderCell className="text-center">Screenshot</CTableHeaderCell>
                      {/* <CTableHeaderCell className="text-center">Action</CTableHeaderCell> */}
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {packages?.history?.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={index}>
                        {/* <CTableDataCell>{index + 1})</CTableDataCell>
                    <CTableDataCell className="text-center">
                      <div className="image-size">
                        <CAvatar size="md" src={item.my_selfie} className="image-profile" />
                      </div>
                    </CTableDataCell> */}
                        {/* <CTableDataCell>
                          <CFormCheck
                            checked={selectedItems.includes(item.id)}
                            onChange={() => toggleItemSelection(item.id)}
                            color="primary"
                          />
                        </CTableDataCell> */}
                        <CTableDataCell>
                          <div>{item.name}</div>
                          <div className="small text-medium-emphasis">
                            <strong>Age: {item.age} </strong>
                            {item.sex === 'Female' ? (
                              <CIcon icon={cilUserFemale} />
                            ) : (
                              <CIcon icon={cilUser} />
                            )}{' '}
                            {item.sex}
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div className="ellipsis" data-text={item.email_id}>
                            {item.email_id}
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div className="small text-medium-emphasis">
                            <strong>{item.package_name}</strong>
                          </div>
                          <div className="clearfix">
                            <small className="text-medium-emphasis">{item.package_duration}</small>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <small className="text-medium-emphasis">{item.package_amount}</small>
                          </div>
                        </CTableDataCell>

                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <small className="text-medium-emphasis">
                              {DateTimeConverter(item.start_date)}
                            </small>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <div className="clearfix">
                            <small className="text-medium-emphasis">
                              {DateTimeConverter(item.expiry_date)}
                            </small>
                          </div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div className="small text-medium-emphasis">{item.transaction_id}</div>
                          <small>{item.payment_method}</small>
                        </CTableDataCell>
                        <CTableDataCell>
                          {/* <div className="small text-medium-emphasis">{item.status}</div> */}
                          <CFormSelect
                            size="sm"
                            color="info"
                            options={[
                              { label: 'Status', value: '', disabled: true },
                              ...['Pending', 'Accepted', 'Rejected'].map((v) => ({
                                label: v,
                                value: v,
                              })),
                            ]}
                            value={item.status}
                            onChange={(event) => ApprovedBtn(event.target.value, item.user_code)}
                          />
                        </CTableDataCell>
                        <CTableDataCell className="text-center">
                          <CButton color="white" size="sm" href={item.screenshot} target="blank">
                            <CIcon icon={cilMoney} className="text-info" size="xxl" />
                          </CButton>
                        </CTableDataCell>
                        {/* <CTableDataCell>
                            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                              <CButton
                                color="danger"
                                size="sm"
                                label="Rejected"
                                title="Rejected"
                                onClick={() => ApprovedBtn('Rejected', item.user_code)}
                              >
                                <CIcon icon={cilXCircle} className="text-white" />
                              </CButton>
                              <CButton
                                color="success"
                                size="sm"
                                label="Accepted"
                                title="Accepted"
                                onClick={() => ApprovedBtn('Accepted', item.user_code)}
                              >
                                <CIcon icon={cibSymantec} className="text-white" />
                              </CButton>
                            </CButtonGroup>
                          </CTableDataCell> */}
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </PerfectScrollbar>
            </CCol>

            <ul className="pagination justify-content-center">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={packages?.total || 0}
                hideFirstLastPages={false}
                onChange={handlePageChange}
                pageRangeDisplayed={5}
                nextPageText="Next"
                prevPageText="Previous"
                lastPageText="Last"
                firstPageText="1st"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="active"
                activeLinkClass="active"
                hideDisabled={false}
              />
            </ul>
          </>
        )}
      </CRow>
    </CContainer>
  )
}

export default SpecialPackageHistory
