import { toast } from 'react-toastify'
import URL from 'src/apis/URL'

export const getSubscriptionDataRequest = () => ({
  type: 'GET_SUBSCRIPTION_DATA_REQUEST',
})

export const getSubscriptionDataSuccess = (data) => ({
  type: 'GET_SUBSCRIPTION_DATA_SUCCESS',
  data,
})

export const getSubscriptionDataFailure = (error) => ({
  type: 'GET_SUBSCRIPTION_DATA_FAILURE',
  error,
})

export const deleteSubscriptionDataRequest = () => ({
  type: 'DELETE_SUBSCRIPTION_DATA_REQUEST',
})

export const deleteSubscriptionDataSuccess = () => ({
  type: 'DELETE_SUBSCRIPTION_DATA_SUCCESS',
})

export const deleteSubscriptionDataFailure = (error) => ({
  type: 'DELETE_SUBSCRIPTION_DATA_FAILURE',
  error,
})

export const getSubscriptionData = (search, status, page = 1) => {
  return (dispatch) => {
    dispatch(getSubscriptionDataRequest())

    let url = URL.BASE_URL + URL.END_POINT.packageusers

    let obj = {}
    if (page) obj.page = page
    if (status) obj.status = status
    if (search) obj.search = search

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getSubscriptionDataSuccess(data))
      })
      .catch((error) => {
        dispatch(getSubscriptionDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const getSpecialSubscriptionData = (search, status, page = 1) => {
  return (dispatch) => {
    dispatch(getSubscriptionDataRequest())

    let url = URL.BASE_URL + URL.END_POINT.specialpackageusers

    let obj = {}
    if (page) obj.page = page
    if (status) obj.status = status
    if (search) obj.search = search

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getSubscriptionDataSuccess(data))
      })
      .catch((error) => {
        dispatch(getSubscriptionDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const getAdsSubscriptionData = (search, status, page = 1) => {
  return (dispatch) => {
    dispatch(getSubscriptionDataRequest())

    let url = URL.BASE_URL + URL.END_POINT.adspackageusers

    let obj = {}
    if (page) obj.page = page
    if (status) obj.status = status
    if (search) obj.search = search

    Object.keys(obj).forEach((key, i) => {
      if (i === 0) url += `?${key}=${obj[key]}`
      else url += `&${key}=${obj[key]}`
    })

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then((data) => {
        dispatch(getSubscriptionDataSuccess(data))
      })
      .catch((error) => {
        dispatch(getSubscriptionDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteSubscriptionData = (user_code) => {
  return (dispatch) => {
    dispatch(deleteSubscriptionDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.packageusers + user_code, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteSubscriptionDataSuccess())
        getSubscriptionData()
        toast('Subscription deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteSubscriptionDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteSpecialSubscriptionData = (user_code) => {
  return (dispatch) => {
    dispatch(deleteSubscriptionDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.specialpackageusers + user_code, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteSubscriptionDataSuccess())
        getSubscriptionData()
        toast('Subscription deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteSubscriptionDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

export const deleteAdsSubscriptionData = (user_code) => {
  return (dispatch) => {
    dispatch(deleteSubscriptionDataRequest())

    fetch(URL.BASE_URL + URL.END_POINT.adspackageusers + user_code, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        USERID: localStorage.getItem('USERID'),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        dispatch(deleteSubscriptionDataSuccess())
        getSubscriptionData()
        toast('Subscription deleted successfully', { type: 'success' })
      })
      .catch((error) => {
        dispatch(deleteSubscriptionDataFailure(error.message))
        toast(error.message, { type: 'error' })
      })
  }
}

const initialAppState = {
  subscriptionData: [],
  loadingSubscriptionData: false,
  errorSubscriptionData: null,
}

const subscriptionReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_SUBSCRIPTION_DATA_REQUEST':
    case 'DELETE_SUBSCRIPTION_DATA_REQUEST':
      return {
        ...state,
        loadingSubscriptionData: true,
        errorSubscriptionData: null,
      }
    case 'GET_SUBSCRIPTION_DATA_SUCCESS':
    case 'DELETE_SUBSCRIPTION_DATA_SUCCESS':
      return {
        ...state,
        subscriptionData: action.data,
        loadingSubscriptionData: false,
        errorSubscriptionData: null,
      }
    case 'GET_SUBSCRIPTION_DATA_FAILURE':
    case 'DELETE_SUBSCRIPTION_DATA_FAILURE':
      return {
        ...state,
        loadingSubscriptionData: false,
        errorSubscriptionData: action.error,
      }
    default:
      return state
  }
}

export default subscriptionReducer
